<template>
  <div class="history-asset">
    <b-card title="Lịch sử vật dụng">
      <div class="search-history-item">
        <b-row>
          <b-col lg="4" md="4" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
              <v-select label="organizationBranchName" @input="getListDeivce" v-model="modelSearchHistoryDevice.organizationBranchId" :reduce="(department) => department.id" :options="listDepartment"></v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Vật dụng</label>
              <v-select @input="getListHistoryDevice" v-model="modelSearchHistoryDevice.deviceId" label="type" :reduce="(type) => type.id" :options="listAllItem"></v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12">
            <div class="time-search">
              <b-row>
                <b-col lg="6">
                  <div class="start-date-time">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Từ ngày</label>
                      <flat-pickr v-model="modelSearchHistoryDevice.startDate" @input="onValidateDate"
                          class="form-control"
                          :config="configFlatpickr"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="end-date-time">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Đến ngày</label>
                      <flat-pickr v-model="modelSearchHistoryDevice.endDate"  @input="onValidateDate"
                          class="form-control"
                          :config="configFlatpickr"
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col lg="2" md="2" xs="12">
            <div class="acction-search-history">
              <b-button @click="getListHistoryDevice"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col lg="12" md="12" xs="12" sm="12">
          <div class="data-table-history-asset mt-2">
            <span class="custom-span">Có tổng số {{totalCount}} bản ghi</span>
            <b-table :items="listHistoryItem"
                     :fields="fieldHistory"
                     :per-page="perPage"
                     :current-page="currentPage">
              <template #cell(index)="data">
                {{data.index + 1}}
              </template>
              <template #cell(fullname)="data">
                <span v-if="data.item.fullName">{{data.item.employeeCode}} - {{data.item.fullName}}</span>
              </template>
              <template #cell(historyType)="data">
                <b-badge v-if="data.value === '4'" variant="danger">
                  Thu hồi
                </b-badge>
                <b-badge v-if="data.value === '5'" variant="success">
                  Thêm mới
                </b-badge>
                <b-badge v-if="data.value === '3'" variant="info">
                  Cấp phát
                </b-badge>
              </template>
              <template #cell(action)="data">
                <b-button @click="updateAllcation(data.item)"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-success"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button @click="recallAllocation(data.item)"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-danger"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="UserMinusIcon" />
                </b-button>
              </template>
            </b-table>
            <b-pagination style="float: right" class="mt-2"
                          v-model="currentPage"
                          :per-page="perPage"
                          hide-goto-end-buttons
                          :total-rows="rows"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BTable, BPagination, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import * as moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    flatPickr,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      listAllItem: [],
      listHistoryItem: [],
      currentPage: 1,
      perPage: 10,
      modelSearchHistoryDevice: {
        deviceType: 1,
        organizationBranchId: null,
        deviceId: null,
        startDate: '',
        endDate: '',
      },
      totalCount: 0,
      fieldHistory: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-index',
        },
        {
          key: 'type', label: 'Vật dụng', thClass: 'custom-th-type',
        },
        {
          key: 'fullName', label: 'Nhân viên', thClass: 'custom-th-full-name',
        },
        {
          key: 'quantity', label: 'Sl cấp', thClass: 'custom-th-quantity',
        },
        {
          key: 'historyType', label: 'Trạng thái', thClass: 'custom-th-status',
        },
        {
          key: 'createdDate', label: 'Thời gian', thClass: 'custom-th-date',
        },
      ],
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  computed: {
    rows() {
      return this.listHistoryItem.length
    },
  },
  created() {
    this.getListHistoryDevice()
    this.getListDepartment()
    if (this.modelSearchHistoryDevice.organizationBranchId === null) {
      this.getListAllDevice()
    }
  },
  methods: {
    updateAllcation(item) {
      this.$router.push({
        name: 'update-allocation-employee',
        query: {
          idAllocation: item.id,
        },
      })
    },
    onValidateDate() {
      if (this.modelSearchHistoryDevice.startDate !== '' && this.modelSearchHistoryDevice.endDate !== '') {
        const startDate = new Date(this.modelSearchHistoryDevice.startDate)
        const endDate = new Date(this.modelSearchHistoryDevice.endDate)
        if (startDate > endDate) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lỗi!',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Từ ngày phải nhỏ hơn đến ngày',
            },
          })
        }
      }
    },
    getListHistoryDevice() {
      this.listHistoryItem = []
      if (this.modelSearchHistoryDevice.endDate !== '' && this.modelSearchHistoryDevice.startDate !== '') {
        const startDate = new Date(this.modelSearchHistoryDevice.startDate)
        const endDate = new Date(this.modelSearchHistoryDevice.endDate)
        if (startDate > endDate) {
          this.isValidateDate = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lỗi!',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Từ ngày phải nhỏ hơn đến ngày',
            },
          })
          return
        }
      }
      if (this.modelSearchHistoryDevice.startDate) {
        this.modelSearchHistoryDevice.startDate = moment(this.modelSearchHistoryDevice.startDate).format('yyyy-MM-DDT00:00:00')
      }
      if (this.modelSearchHistoryDevice.endDate) {
        this.modelSearchHistoryDevice.endDate = moment(this.modelSearchHistoryDevice.endDate).format('yyyy-MM-DDT23:59:59')
      }
      this.$crm.post('device/find-device-history', this.modelSearchHistoryDevice).then(res => {
        this.listHistoryItem = res.data
        this.totalCount = this.listHistoryItem.length
        for (let i = 0; i < this.listHistoryItem.length; i++) {
          this.listHistoryItem[i].createdDate = moment(this.listHistoryItem[i].createdDate).format('DD-MM-YYYY')
        }
      })
    },
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getListAllDevice() {
      this.$crm.post('device/find-by-condition', this.modelSearchHistoryDevice).then(res => {
        this.listAllItem = res.data
      })
    },
    getListDeivce() {
      if (this.modelSearchHistoryDevice.organizationBranchId === null) {
        this.modelSearchHistoryDevice.deviceId = null
        this.getListAllDevice()
        this.getListHistoryDevice()
      } else {
        this.getListHistoryDevice()
        this.$crm.post('device/find-by-condition', this.modelSearchHistoryDevice).then(res => {
          this.listAllItem = res.data
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/history-asset.scss';
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
